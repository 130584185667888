import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useResponsibilityGroupsStore = defineStore('responsibilityGroupsStore', {
  state: () => ({
    responsibilityGroups: [] as any[],
    workingPlaces: [] as any[]
  }),
  actions: {
    async fetchResponsibilityGroups() {
      if (this.responsibilityGroups.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/responsibilityGroup/find')
          this.responsibilityGroups = result.data
        } catch (e) {
          showError(e)
        }
      }
    },
    async fetchWorkingPlaces() {
      if (this.workingPlaces.length === 0) {
        try {
          const result = await app.axios.get(apiURL + '/tsmOffices/find')
          this.workingPlaces = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
